import { Behavior } from 'backbone.marionette';
import $ from 'jquery';

var FormReveal = Behavior.extend({
    ui: {
        formReveal: '[data-reveal]:not(button,a)',
        formRevealButton: 'button[data-reveal],a[data-reveal]',
    },
    events: {
        'change @ui.formReveal': 'revealTargets',
        'click @ui.formRevealButton': 'revealButton',
    },
    onRender: function () {
        this.onAttach();
    },
    onViewAttach: function(){
        this.onAttach();
    },
    onComponentChange: function(){
        this.onAttach();
    },
    onAttach: function () {
        var self = this;

        console.log('reveal:attach');

        this.ui.formReveal.filter('input:checked,select').each(function(){
            self.revealTargets({
                currentTarget: this
            })
        })

        this.ui.formRevealButton.filter('[data-reveal-active]').each(function(){
            self.revealButton({
                currentTarget: this
            })
        })
    },
    revealTargets: function(e) {
        var el = $(e.currentTarget);
        var value = el.val();
        var form = el.parents('form');
        var selectCondition = el.data('selectCondition');
        var target = selectCondition ? el.data('selectTarget') : el.attr('name') || el.attr('data-reveal');
        var targets = form.find('[data-reveal-listener="' + target + '"]');

        if (el.filter('input[type=checkbox],input[type=radio]').length > 0 || selectCondition) {
            targets.hide();

            if (el.is(":checked") || selectCondition == value) {
                targets.filter('[data-reveal-value="checked"]').show().find('input,textarea,select').trigger('ontop');
                targets.filter('[data-reveal-value~="' + value + '"]').show().find('input,textarea,select').trigger('ontop');
            } else {
                targets.filter('[data-reveal-value="unchecked"]').show().find('input,textarea,select').trigger('ontop');
                targets.filter('[data-reveal-value~="' + value + '"]').hide();
            }
        } else {
            targets.hide();
            targets.filter('[data-reveal-value~="' + value + '"]').show().find('input,textarea,select').trigger('ontop');
        }

        this.view.triggerMethod('reveal', target, value, targets);
        el.trigger('reveal', [target, value, targets]);
    },
    revealButton: function(e){
        var el = $(e.currentTarget);
        var value = el.val();
        var form = el.parents('form');
        var selectCondition = el.data('selectCondition');
        var target = selectCondition ? el.data('selectTarget') : el.attr('name') || el.attr('data-reveal');
        var targets = form.find('[data-reveal-listener="' + target + '"]');

        if (el.filter('button,a').length > 0 || selectCondition) {
            targets.hide();
            targets.filter('[data-reveal-value="' + value + '"]').show();
        }

        this.view.triggerMethod('reveal', target, value, targets);
        el.trigger('reveal', [target, value, targets]);

        setTimeout(function(){
            $(window).resize();
        }, 250);
    }
});

export default FormReveal;
